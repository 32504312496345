import request from '../request'

//休假申请列表
export const vacation_index_request = p => {
    return request({
        method:'GET',
        url:'vacation/index',
        params: p
    })
}

//发起休假申请
export const vacation_add_request = d => {
    return request({
        method:'POST',
        url:'vacation/add',
        data: d
    })
}

//删除休假申请
export const vacation_del_request = id => {
    return request({
        method:'DELETE',
        url:'vacation/del',
        data: {
            id: id
        }
    })
}

//修改休假申请
export const vacation_edit_request = data => {
    return request({
        method:'PUT',
        url:'vacation/edit',
        data
    })
}

//获取休假申请详情
export const vacation_details_request = id => {
    return request({
        method:'GET',
        url:'vacation/details',
        params: {
            id
        }
    })
}

// 提交休假申请
export const vacation_to_approval_request = d => {
    return request({
        method:'PUT',
        url:'vacation/to_approval',
        data: {
            id:d.id,
            note:d.note
        }
    })
}

// 查看休假申请审批记录
export const vacation_sel_approval_request = id => {
    return request({
        method:'GET',
        url:'vacation/sel_approval',
        params: {
            id
        }
    })
}

// 审批休假申请
export const vacation_approval_request = d => {
    return request({
        method:'PUT',
        url:'vacation/approval',
        data: {
            id: d.id,
            opinion: d.opinion,
            note: d.note,
            other: d.other
        }
    })
}

// 撤回休假申请
export const vacation_withdraw_request = d => {
    return request({
        method:'PUT',
        url:'vacation/withdraw',
        data: {
            id: d.id
        }
    })
}

// 打印休假申请
export const vacation_print_request = id => {
    return request({
        method:'PUT',
        url:'vacation/print',
        data: {
            id
        }
    })
}